import React from 'react'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import Banner from '../components/NewTheme/Ui/Banner'
import BigButton from '../components/Ui/BigButton'
import Wolflayout from '../components/wolflayout'

const Benevolat = () => {
  const metaTitle = 'Bénévolat | Le Festif! de Baie-Saint-Paul'
  const metaDescription = 'bénévolat, festival, festif, baie-saint-paul, gratuit'
  const metaKeywords = 'bénévolat, festival, festif, baie-saint-paul, gratuit'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <Banner title="Bénévolat" />
      <div className="container content pb-12">

        {/* TOP IMAGE */}
        {/* <div className="max-w-[2048px] max-h-[767px] pb-12">
          <StaticImage src="../../images/ruefestive.png" alt="Ambiance de la Rue Festive" imgClassName="rounded-3xl"/>
        </div> */}

        {/* YOUTUBE */}
        <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
          <div className="mt-10 relative h-[35rem]">
            <iframe
              title="C'EST ÇA LE FESTIF! : Les bénévoles 🤗"
              className="absolute top-0 left-0 w-full h-full mb-3rem"
              src={'https://www.youtube.com/embed/IGu9tS9e2QI'}
              allowFullScreen=""
              referrerPolicy="origin-when-cross-origin"
              height={'100%'}
            ></iframe>
          </div>
        </div>

        <h2>Le cœur et l’âme du Festif!, ce sont ses extraordinaires bénévoles qui, année après année, soutiennent le festival avec enthousiasme et, par leur générosité et leur dévouement, lui permettent de briller de mille feux!</h2>
        <p>Le Festif! t'offre, en échange de chaque quart (4h) de bénévolat:</p>
        <div className='ul-container'>
          <ul className="mb-8">
            <li>Une consommation gratuite / minimum 4 heures</li>
            <li>1 billet pour la place Desjardins / minimum 8 heures</li>
            <li>1 passe pour la place Desjardins / minimum 16 heures</li>
          </ul>
        </div>
        <p>Pour obtenir des billets pour d'autres spectacles/scènes, il sera possible d'en discuter directement avec la responsable. Un nombre restreint de places peut être débloqué pour les bénévoles.</p>
        <br/>
        <p>Il est également possible d'avoir accès gratuitement au camping en échange de 4 quarts.</p>
        <br/>
        <p>Les quarts sont généralement entre 4h et 6h. S'ils sont plus longs, nous t'offrirons des coupons de consommation supplémentaires.</p>
        <br/>
        <p>Les bénévoles sont essentiel·les à la réussite du festival, il est donc important d’honorer les termes de ton engagement en te présentant sans faute au quart de travail qui t'aura été assigné. Si tu n'es pas en mesure de t’y présenter, il est important d'en aviser la responsable des bénévoles dès que possible, afin que nous ayons le temps de te remplacer.</p>
        <h3>Intéressé·e à joindre les rangs de notre formidable équipe de bénévoles ?</h3>
        <p>Nous t'invitons à nous transmettre tes coordonnées en cliquant sur le bouton ci-dessous. Notre responsable des bénévoles se fera un plaisir de te contacter au moment opportun!</p>
        <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
          <p>
              <BigButton
                  href="https://form.zonefestival.com/?k=festif_v1"
                  target="_blank"
                  text="JE VEUX M'IMPLIQUER" />
          </p>
        </div>
        <p>Pour toute question relative au système de bénévolat du Festif!, tu peux nous écrire à l’adresse <a href="mailto:benevoles@lefestif.ca" >benevoles@lefestif.ca</a> et nous te répondrons aussi rapidement que possible.
        </p>
        <br/>
        <p>
          Joins-toi à notre{' '}
          <a
            href="https://www.facebook.com/groups/benevolesfestif/"
            target="_blank"
            rel="noopener noreferrer"
          >
            groupe
          </a>{' '}
          Facebook officiel.
        </p>
      </div>
    </Wolflayout>
  )
}

export default Benevolat
